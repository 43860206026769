import React from 'react'
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import {graphql} from 'gatsby'
import ReactMarkdown from "react-markdown"
import Seo from '../../components/Seo'
const ProjectTemplate = ({ pageContext: { title }, data}) => {
	return (
		<>
			<main className="project-template-page">
			<h2>{title}</h2>
      <body dangerouslySetInnerHTML={{ __html: data.strapiProject.testing}}/>
			</main>
		</>
	)
}

export const query = graphql`
  
 query getSingleProject($title: String) {
  strapiProject(title: {eq: $title}) {
    description
    title
    image {
      localFile {
        publicURL
      }
    }
    testing
  }
}

`
export default ProjectTemplate